<template>
	<div class="search">
		<div class="search-title">{{ '搜素设备' | language }}</div>
		<Input
			prefix="ios-search"
			:placeholder="'输入名称、编号' | language"
			style="width: 164px;margin-top:10px;"
			v-model="keyword"
			clearable
			@keyup.enter.native="fetchDevice()"
		/>
		<div class="search-title mr-t2">{{ '热门预约' | language }}</div>
		<div class="hot-list">
			<div class="hot-item" v-for="(item, index) in hot" @click="handleClick(item)">
				<div class="hot-index">{{ index + 1 }}</div>
				<div class="hot-text click-op">{{ item.name }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			hot: [],
			keyword: '',
		};
	},
	mounted() {
		this.fetchHot();
	},
	methods: {
		handleClick(item) {
			this.$router.push(`/base/service/device/detail/${item._id}`);
		},

		fetchDevice() {
			this.$emit('search', this.keyword);
		},
		//获取热门 搜素
		async fetchHot() {
			let res = await this.$http.get('/device/hot');
			this.hot = res;
		},
	},
};
</script>
<style scoped>
.ivu-input {
	border-radius: 19px;
	padding-left: 25px;
}
</style>
<style lang="less" scoped>
.search {
	width: 170px;
	// .notice {
	//   display: flex;
	//   align-items: center;
	//   color: #2c8dfc;
	//   p {
	//     height: 20px;
	//     font-size: 16px;

	//     font-weight: 600;
	//     color: rgba(44, 141, 252, 1);
	//     line-height: 20px;
	//   }
	//   img {
	//     margin-left: 10px;
	//   }
	// }
	.search-title {
		width: 100%;
		text-align: left;
		// margin-top: 20px;
		height: 24px;
		font-size: 16px;

		font-weight: 600;
		color: rgba(0, 0, 0, 0.6);
		line-height: 24px;
	}
	.hot-list {
		width: 100%;
		margin-top: 20px;
		.hot-item {
			display: flex;
			align-items: center;

			margin-bottom: 10px;
			.hot-index {
				width: 18px;
				height: 18px;
				background: #f9cd16;
				height: 18px;
				font-size: 10px;
				font-weight: 600;
				color: rgba(255, 255, 255, 1);
				line-height: 18px;
			}
			.hot-text {
				max-width: 126px;
				margin-left: 20px;
				height: 18px;
				font-size: 14px;

				font-weight: 600;
				color: rgba(44, 141, 252, 1);
				line-height: 18px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
</style>
