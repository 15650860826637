<template>
    <div class="container">
        <div class="left">
            <div class="title">{{ "预约须知" | language }}</div>
            <div class="list-title">设备管理条例：</div>
            <div class="list-item">
                1、设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例；
            </div>
            <div class="list-item">
                2、设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例；
            </div>
            <div class="list-item">
                3、设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例；
            </div>
            <div class="list-item">
                4、设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例；
            </div>
            <div class="list-item">
                5、设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例；
            </div>
            <div class="list-item">
                6、设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例；
            </div>
            <div class="list-item">
                7、设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例；
            </div>
            <div class="list-item">
                8、设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例设备管理条例；
            </div>
            <div class="list-title" style="margin-top:60px;">
                {{ "信用积分说明" | language }}：
            </div>
            <div class="list-item">
                1、信用积分说明信用积分说明信用积分说明信用积分说明信用积分说明信用积分说明；
            </div>
            <div class="list-item">
                2、信用积分说明信用积分说明信用积分说明；
            </div>
            <div class="list-item">
                3、信用积分说明信用积分说明信用积分说明信用积分说明；
            </div>
            <div class="list-item">
                4、信用积分说明信用积分说明信用积分说明信用积分说明信用积分说明。
            </div>
        </div>
        <div class="right">
            <Search @search="handleSearch"></Search>
        </div>
    </div>
</template>

<script>
import Search from "../../compontents/service/device/Search.vue";
export default {
    components: {
        Search
    },
    data() {
        return {};
    },
    methods: {
        handleSearch(val) {
            this.$router.push(`/device?keyword=${val}`);
        }
    }
};
</script>

<style lang="less" scoped>
.container {
    width: 1022px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    .left {
        width: 808px;
        .title {
            text-align: left;
            height: 32px;
            font-size: 22px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9);
            line-height: 32px;
        }
        .list-title {
            text-align: left;
            margin-top: 20px;
            height: 24px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
        }
        .list-item {
            text-align: left;
            margin-top: 10px;
            height: 24px;
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
        }
    }
    .right {
        // margin-top: 60px;
    }
}
</style>
